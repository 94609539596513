import TabGrid from "components/cards/TabCardGrid.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";

import celebrationIconImageSrc from "images/celebration-icon.svg";
import chefIconImageSrc from "images/chef-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-black text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-2 text-gray-700`;
  const imageCss = tw`rounded-4xl border-white`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Serwis do zarządzania <HighlightedText>ekspozycją produktów.</HighlightedText></>}
        description={
          <Description>
            Serwis SaaS do zdalnego zarządzania ekspozycją w wielu placówkach w jednym czasie. 
            Zintegrowany z elektronicznymi etykietami cenowymi (ESL) oraz lokalnym systemem sprzedażowym automatycznie aktualizuje informacje o produktach na półkach. Etykiety wykonane są w technologii e-papieru i są ekologiczną odpowiedzią na ekrany LED-owe ponieważ pobierają zdecydowanie mniej energii.
            Wyświetlacz wykonany w wysokiej rozdzielczości. Szeroki wybór rozmiarów. Wdrożenia w chmurze lub on premieses, możliwa integracja API. Indywidualne wyceny.
          </Description>
        }
        imageSrc="pic1.png"
        imageCss={imageCss}
        // imageDecoratorBlob={true}
        primaryButtonText="Skontaktuj się z nami"
        watchVideoButtonText="Zobacz film"
      />
      <MainFeature
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Branża
            <HighlightedText> detaliczna.</HighlightedText>
            <wbr />
          </>
        }
        description={
          <Description>
            
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc="pic7.png"
        imageCss={imageCss}
        // imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Branża
            <HighlightedText> farmaceutyczna.</HighlightedText>
            <wbr />
          </>
        }
        description={
          <Description>
            
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc="pic3.png"
        imageCss={imageCss}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Inteligentne
            <HighlightedText> biura.</HighlightedText>
            <wbr />
          </>
        }
        description={
          <Description>
            
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc="pic4.png"
        imageCss={imageCss}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Modele <HighlightedText>i rozmiary:</HighlightedText>
          </>
        }
      />
      {/* <Features
        heading={
          <>
            Dostarczamy <HighlightedText>Branze.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "230+ Locations",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Professional Chefs",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Birthday Catering",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      /> */}
      <MainFeature2
        // subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Dlaczego warto z nami <HighlightedText>współpracować?</HighlightedText></>}
        statistics={[
          {
            key: "Klienci",
            value: "50+"
          },
          {
            key: "Projekty",
            value: "60+"
          },
          {
            key: "Serwisy",
            value: "2"
          }
        ]}
        //primaryButtonText="Skontaktuj się z nami"
        //primaryButtonUrl="https://datagrit.pl"
        imageInsideDiv={false}
        imageSrc="pic5.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
